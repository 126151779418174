var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"964ffb89b4708cea4513e61c44412b208c46a47e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// @ts-check

import * as Sentry from '@sentry/nextjs'
import { isLocalDev } from './src/env/utils/isLocalDev'

const environment = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT || 'local'
if (!isLocalDev) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment,
    tracesSampleRate: 1.0,
  })
}
