import isNil from 'lodash/isNil'
import { NonNullableNonOptionalObject } from '../typescript/utility'
import { isBuildPhaseNotRuntime } from './utils/isBuildPhaseNotRuntime'
import { isCodegenRun } from './utils/isCodegenRun'

const optionalRuntimeEnvs = {
  NEXT_PUBLIC_REACT_PROFILING: process.env.NEXT_PUBLIC_REACT_PROFILING,
  NEXT_PUBLIC_IS_CODEGEN_RUN: process.env.NEXT_PUBLIC_IS_CODEGEN_RUN,
  PORT: process.env.PORT,
  BUILD_DANGEROUSLY: process.env.BUILD_DANGEROUSLY,
  ANALYZE: process.env.ANALYZE,
  TURBOPACK: process.env.TURBOPACK,
  NEXT_PHASE: process.env.NEXT_PHASE,
  NEXT_RUNTIME: process.env.NEXT_RUNTIME,
}
const isNonRuntime = isBuildPhaseNotRuntime || isCodegenRun

const unverifiableRuntimeDockerClientSideEnvs = {
  NEXT_PUBLIC_GIT_SHA: process.env.NEXT_PUBLIC_GIT_SHA,
  NEXT_PUBLIC_GIT_TAG: process.env.NEXT_PUBLIC_GIT_TAG,
  NEXT_PUBLIC_HONEYCOMB_API_KEY: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY,
}

const unverifiedClientEnv = {
  NEXT_PUBLIC_AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_FACEBOOK_CONVERSIONS_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_CONVERSIONS_PIXEL_ID,
  NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_HASURA_ENDPOINT: process.env.NEXT_PUBLIC_HASURA_ENDPOINT,
  NEXT_PUBLIC_NEST_API_ENDPOINT: process.env.NEXT_PUBLIC_NEST_API_ENDPOINT,
  NEXT_PUBLIC_NEXT_IMAGE_PATH: process.env.NEXT_PUBLIC_NEXT_IMAGE_PATH,
  NEXT_PUBLIC_OPTIMIZELY_SDK_KEY: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
  NEXT_PUBLIC_ORIGIN_SERVER_URL: process.env.NEXT_PUBLIC_ORIGIN_SERVER_URL,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT: process.env.NEXT_PUBLIC_STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT,
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
}

const unverifedServerEnv = {
  CACHE_REQUIRE_VERSION_QUERY: process.env.CACHE_REQUIRE_VERSION_QUERY,
  DEBUG: process.env.DEBUG,
  ENVIRONMENT: process.env.ENVIRONMENT,
  NEW_RELIC_APP_NAME: process.env.NEW_RELIC_APP_NAME,
  PORT: process.env.PORT,
  ZENDESK_API: process.env.ZENDESK_API,
  ZENDESK_KEY: process.env.ZENDESK_KEY,
  ZENDESK_USER: process.env.ZENDESK_USER,
  FACEBOOK_CONVERSIONS_API_ACCESS_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_ACCESS_TOKEN,
  HASURA_ADMIN_SECRET: process.env.HASURA_ADMIN_SECRET,
  NEW_RELIC_LICENSE_KEY: process.env.NEW_RELIC_LICENSE_KEY,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  ...unverifiedClientEnv,
}

function assertEnv<T extends Record<string, string | undefined>>(
  env: T,
  optionalKeys: (keyof T)[] = []
): asserts env is NonNullableNonOptionalObject<Omit<T, (typeof optionalKeys)[number]>> &
  Pick<T, (typeof optionalKeys)[number]> {
  // do not test during the build phase as many env vars will not be available in each build until runtime
  if (!isNonRuntime) {
    const keys = Object.keys(env) as (keyof T)[]
    const missingKeys = [] as string[]
    keys.forEach((key) => {
      if (!env[key]?.length && !optionalKeys.includes(key)) {
        missingKeys.push(key as string)
      }
    })
    if (missingKeys.length > 0) {
      throw new Error(`Missing environment variables: ${missingKeys.join(', ')}`)
    }
  }
}

export const getUnverifiableRuntimeDockerClientSideEnvs = () => {
  return unverifiableRuntimeDockerClientSideEnvs
}
// if we are not in the runtime, we need to convert nulls to strings in case they are used in the code such as a string
// .includes() call etc. during the build, doesn't actually matter if they are present so long as there are no hard
// errors. once we actually hit runtime, we need to ensure that all env vars are present and not null, and if not, throw
// in which case the container will not start and we can avoid runtime issues and maintain type safety, all while
// avoiding having to unnecessarily ensure all env vars are present during the build phase, which we only do once and
// then promote the same build, so even if we did manually set all of them, they would be pointless because they would
// not actually be representative of any env since the container is used for all environments
export function convertNullsToStringsIfNotDuringRuntime<T extends object>(input: T) {
  if (isNonRuntime) {
    return Object.fromEntries(Object.entries(input).map(([key, value]) => [key, isNil(value) ? '' : value]))
  }
  return input
}

export const getOptionalRuntimeEnvs = () => {
  return optionalRuntimeEnvs
}

export const getServerSideEnv = () => {
  if (typeof window !== 'undefined') throw 'Server Side ENV attempted access on client'
  assertEnv(unverifedServerEnv)
  return convertNullsToStringsIfNotDuringRuntime(unverifedServerEnv)
}

export const getClientSideEnv = () => {
  assertEnv(unverifiedClientEnv)
  return convertNullsToStringsIfNotDuringRuntime(unverifiedClientEnv)
}
